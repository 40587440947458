import React from 'react';
import classNames from 'classnames';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  name: string;
  type: string;
  placeholder: string;
  isInvalid: boolean;
}

const inputStyles = {
  base: 'form-input block w-full rounded-md border pl-14 py-1.5 pr-10 transition-colors duration-300 ease-in-out',
  textColor: (isInvalid: boolean) =>
    isInvalid
      ? 'text-red-900 border-red-300 placeholder:text-red-300'
      : 'black border-gray-300',
  focusStyles: 'focus:outline-none focus:border-blue-500',
  transition: 'background-color 5000s ease-in-out',
};

const AuthInput: React.FC<InputProps> = ({
  id,
  name,
  type,
  placeholder,
  isInvalid,
  onChange,
  ...rest
}) => {
  return (
    <input
      id={id}
      name={name}
      type={type}
      placeholder={placeholder}
      aria-describedby={`${id}-error`}
      autoComplete="on"
      className={classNames(
        inputStyles.base,
        inputStyles.textColor(isInvalid),
        inputStyles.focusStyles
      )}
      onChange={onChange}
      {...rest}
    />
  );
};

export default AuthInput;