// lib/routes/auth/src/lib/frontend/components/organisms/auth/auth-form.tsx
import { useState, useEffect } from 'react';
import { Form, useActionData, useNavigation } from '@remix-run/react';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';

import HeaderMolecule from '../../molecules/auth/auth-header';
import ButtonAtom from '../../atoms/button.atom.ui';
import EmailInputField from '../../molecules/auth/email/email-Input_fields';
import PhoneInputField from '../../molecules/auth/phone/phone-Input';
import { type ActionData } from '../../../types';
import { isMobile } from '@entrycall/utility-screen-size-checker';

// Consolidated styles for the AuthFormOrganism component
const authFormStyles = {
  article: 'bg-white',
  form: 'max-w-md mx-auto',
  tabGroup: 'group flex items-center bg-gray-100 p-2 rounded-3xl',
  tab: 'w-1/2 rounded-full border border-transparent px-3 py-1.5 text-sm font-medium text-gray-500 hover:text-gray-900 data-[selected]:bg-white data-[selected]:text-gray-900',
  tabPanel: 'p-0.5',
  buttonWrapper: (isMobileView: boolean) => `mx-auto mt-4 ${!isMobileView ? 'max-w-[260px]' : ''}`,
};

// Consolidated text for the AuthFormOrganism component
const authFormText = {
  emailTab: 'Email',
  phoneTab: 'Phone',
  loadingButtonText: 'Loading...',
  loginButtonText: 'Login',
  unexpectedError: 'An unexpected error occurred'
};

const AuthFormOrganism: React.FC = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const data = useActionData<ActionData>();
  const navigation = useNavigation();
  const [error, setError] = useState<string | null>(null);
  const [isMobileView, setIsMobileView] = useState<boolean>(false);

  useEffect(() => {
    if (data) {
      const errorMessage = data.details?.errorMessage;
      const error = data.error;

      if (typeof errorMessage === 'string' && errorMessage.trim() !== '') {
        setError(errorMessage);
      } else if (typeof error === 'string' && error.trim() !== '') {
        setError(error);
      } else {
        setError(authFormText.unexpectedError);
      }
    } else {
      setError(null);
    }
  }, [data]);

  useEffect(() => {
    // Update the isMobileView state on component mount to ensure consistent client-side rendering
    setIsMobileView(isMobile());
  }, []);

  const handleChange = () => {
    setError(null);
  };

  const handleTabChange = (index: number) => {
    setSelectedIndex(index);
    setError(null); // Clear error when switching tabs
  };

  const buttonText = navigation.state === 'submitting' ? authFormText.loadingButtonText : authFormText.loginButtonText;

  return (
    <article className={authFormStyles.article}>
      <HeaderMolecule />
      <Form method="post" className={authFormStyles.form}>
        <TabGroup selectedIndex={selectedIndex} onChange={handleTabChange}>
          <TabList className={authFormStyles.tabGroup}>
            <Tab className={authFormStyles.tab}>{authFormText.emailTab}</Tab>
            <Tab className={authFormStyles.tab}>{authFormText.phoneTab}</Tab>
          </TabList>
          <TabPanels className="mt-2">
            <TabPanel className={authFormStyles.tabPanel}>
              <EmailInputField
                error={selectedIndex === 0 ? error : null}
                onChange={handleChange}
              />
            </TabPanel>
            <TabPanel className={authFormStyles.tabPanel}>
              <PhoneInputField
                error={selectedIndex === 1 ? error : null}
                onChange={handleChange}
              />
            </TabPanel>
          </TabPanels>
        </TabGroup>
        <div className={authFormStyles.buttonWrapper(isMobileView)}>
          <ButtonAtom text={buttonText} />
        </div>
      </Form>
    </article>
  );
};

export default AuthFormOrganism;